import React from "react";
import {
    createRoot
} from "react-dom/client";

import * as Sentry from "@sentry/react";

import ReactGA from "react-ga4";
import {
    clarity
} from "clarity-js";

import moment from "moment";
import "moment/locale/nl";

import App from "./App";

import * as serviceWorker from "./serviceWorker";

const development = process.env.REACT_APP_ENVIRONMENT === "development";

if(development) {
    console.log("Running in development mode. Sentry error reporting is disabled.");
} else {
    Sentry.init({
        dsn: "https://9bf90724b3de4f0791d9e317dcdae080@sentry1.zsnode.com/39",
        autoSessionTracking: true,
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration(),
        ],
        tracesSampleRate: 1.0,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    });
}

if(development) {
    console.log("Running in development mode. Google Analytics is disabled.");
} else {
    ReactGA.initialize("G-79FLYKT35M");
    ReactGA.send("pageview");
}

if(development) {
    console.log("Running in development mode. Microsoft Clarity is disabled.");
} else {
    clarity.start({
        projectId: "lxo80027qr",
        upload: "https://m.clarity.ms/collect",
        track: true,
        content: true
    });
}

moment.locale("nl");

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
    <App />
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
