import React from "react";
import {
    BrowserRouter
} from "react-router-dom";
import {
    ShopCrateManager
} from "@shopcrate/shopcrate-framework";

import ShopRouter from "./ShopRouter";

import "./scss/main.scss";

function App() {
    return (
        <BrowserRouter>
            <ShopCrateManager>
                <ShopRouter/>
            </ShopCrateManager>
        </BrowserRouter>
    );
}

export default App;
