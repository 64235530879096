import React, {
    useMemo
} from "react";

import useWindowSize from "../hooks/WindowSize";

import logo from "../img/logo-270.png";

function HeaderBanner() {
    const windowSize = useWindowSize();
    const bannerHeight = useMemo(() => {
        const {
            width,
            height
        } = windowSize;
        if(width < 576) {
            return 200;
        }
        return Math.min(height * 0.4, 450);
    }, [windowSize]);

    return (
        <React.Fragment>
            <div
                className="banner"
                style={{
                    height: bannerHeight + "px"
                }}
                translate="no"
            >
                <div className="banner-overlay">
                    <a href="https://saralindenhols.nl">
                        <img src={ logo } alt="Logo" className="banner-logo"/>
                    </a>
                    <h1 className="banner-title">
                        <a href="https://saralindenhols.nl">
                            Sara Lindenhols
                        </a>
                    </h1>
                    <p className="banner-slogan">
                        Sparkles of creative faith
                    </p>
                </div>
                <div className="banner-background"/>
            </div>
        </React.Fragment>
    );
}

export default HeaderBanner;
