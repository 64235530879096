import React from "react";

function PolicyNoticeCard() {
    return (
        <div className="card mb-3">
            <div className="card-body">
                <p className="card-text">
                    Door op "Afrekenen" te klikken ga je akkoord met de{" "}
                    <a href="https://saralindenhols.nl/privacy-en-cookie-verklaring" target="_blank" rel="noreferrer noopener">
                        Privacyverklaring
                    </a>
                    {" "}en{" "}
                    <a href="https://saralindenhols.nl/algemene-voorwaarden" target="_blank" rel="noreferrer noopener">Algemene voorwaarden</a>.
                </p>
            </div>
        </div>
    );
}

export default React.memo(PolicyNoticeCard);
