import React, {
    useContext
} from "react";
import {
    ProductPageContext
} from "@shopcrate/shopcrate-framework";
import Skeleton from "react-loading-skeleton";

function SaraProductAvailabilityCheckmark() {
    const product = useContext(ProductPageContext);
    if(!product.product) {
        return (
            <p className="mt-3">
                <Skeleton/>
            </p>
        );
    }
    if(!product.product.orderable) {
        return null;
    }
    if(!product.product.digital) {
        return null;
    }
    return (
        <p className="text-success mt-3">
            <i className="fas fa-check-circle mr-2"/>
            <b>Direct beschikbaar</b>
        </p>
    );
}

export default React.memo(SaraProductAvailabilityCheckmark);
