import React, {
    useEffect
} from "react";
import {
    useHistory
} from "react-router-dom";

function Home() {
    const history = useHistory();
    useEffect(() => {
        history.replace("/category/20");
    }, [history]);
    return null;
}

export default React.memo(Home);
