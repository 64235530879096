import React from "react";

function NavbarExternalLink({to, title, onClick}) {
    return (
        <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
            <a href={ to } className="nav-link" onClick={ onClick }>
                { title }
            </a>
        </li>
    );
}

export default React.memo(NavbarExternalLink);
