import React from "react";
import {
    Trans
} from "react-i18next";
import {
    ProductPageContext,
    ProductPageManager,
    ProductPageTitle,
    ProductImageCarousel,
    ProductPageAddToCartButton,
    ProductPageDescription,
    ProductPagePriceDisplay,
    ProductPageStock,
    ProductLimitPerOrderNotice,
    ProductPageStickyBottomBar
} from "@shopcrate/shopcrate-framework";

import SaraProductAvailabilityCheckmark from "./SaraProductAvailabilityCheckmark";

function SaraProductPage(props) {
    return (
        <ProductPageManager { ...props }>
            { props.helmet && (
                <ProductPageContext.Consumer>
                    { productContext => props.helmet(productContext) }
                </ProductPageContext.Consumer>
            )}
            <div className="container">
                <div className="content">
                    <div className="content-title">
                        <ProductPageTitle showSubtitle={ !!props.showSubtitle ? props.showSubtitle : true }/>
                    </div>
                    <div className="content-body">
                        <div className="row">
                            <div className="col-md-8 mb-3">
                                <ProductImageCarousel/>
                            </div>
                            <div className="col-md-4">
                                <ProductPagePriceDisplay showCurrencySymbol={ false }/>
                                <p className="text-muted" style={{ fontSize: "0.8rem" }}>
                                    <b><ProductPageStock/></b>
                                </p>
                                <p className="text-muted" style={{ fontSize: "0.8rem" }}>
                                    <b><ProductLimitPerOrderNotice/></b>
                                </p>
                                <SaraProductAvailabilityCheckmark/>
                                <ProductPageAddToCartButton/>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-8">
                                <h3 className="mt-4">
                                    <Trans i18nKey="description"/>
                                </h3>
                                <ProductPageDescription/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-md-none">
                <ProductPageStickyBottomBar>
                    <ProductPageAddToCartButton/>
                </ProductPageStickyBottomBar>
            </div>
        </ProductPageManager>
    )
}

export default React.memo(SaraProductPage);
