import React from "react";
import {
    useRouteMatch
} from "react-router-dom";

import Helmet from "../components/Helmet";
import PolicyNoticeCard from "../components/PolicyNoticeCard";

function Layout({ children, title }) {
    const orderOverviewMatch = useRouteMatch({
        path: "/order/overview"
    });
    return (
        <React.Fragment>
            <Helmet title={ title }/>
            <div className="container mb-5">
                <div className="content">
                    <div className="content-title">
                        <h1>{ title }</h1>
                    </div>
                    <div className="content-body">
                        { children }
                        { orderOverviewMatch && (
                            <PolicyNoticeCard/>
                        )}
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default React.memo(Layout);
