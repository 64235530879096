import React from "react";

import Helmet from "../components/Helmet";
import Page404 from "../pages/Page404";
import SaraCategoryPage from "../components/category/SaraCategoryPage";

function CategoryLayout() {
    return (
        <SaraCategoryPage
            error404Page={ <Page404/> }
            helmet={ (categoryContext) => (
                <Helmet title={ categoryContext.category ? categoryContext.category.name : null }/>
            )}
        />
    )
}

export default React.memo(CategoryLayout);
