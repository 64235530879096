import React from "react";
import {
    CategoryPageContext,
    CategoryPageManager
} from "@shopcrate/shopcrate-framework";

import SaraCategoryPageTitle from "./SaraCategoryPageTitle";
import SaraCategoryPageProducts from "./SaraCategoryPageProducts";

function SaraCategoryPage(props) {
    return (
        <CategoryPageManager { ...props }>
            { props.helmet && (
                <CategoryPageContext.Consumer>
                    { categoryContext => props.helmet(categoryContext) }
                </CategoryPageContext.Consumer>
            )}
            <div className="container">
                <div className="content">
                    <div className="content-title">
                        <SaraCategoryPageTitle/>
                    </div>
                    <div className="content-body">
                        <SaraCategoryPageProducts/>
                    </div>
                </div>
            </div>
        </CategoryPageManager>
    )
}

export default React.memo(SaraCategoryPage);
