import React, {
    useContext
} from "react";
import {
    CategoryPageContext
} from "@shopcrate/shopcrate-framework";
import Skeleton from "react-loading-skeleton";
import ReactMarkdown from "react-markdown";

function SaraCategoryPageTitle() {
    const categoryContext = useContext(CategoryPageContext);
    return (
        <div>
            { categoryContext.category ? (
                <React.Fragment>
                    <h1>{ categoryContext.category.name }</h1>
                    <div className="p-last-mb-0">
                        <ReactMarkdown>
                            { categoryContext.category.description }
                        </ReactMarkdown>
                    </div>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <h1><Skeleton width={ 300 }/></h1>
                    <Skeleton count={ 3 }/>
                </React.Fragment>
            )}
        </div>
    )
}

export default React.memo(SaraCategoryPageTitle);
