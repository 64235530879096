import React, {
    useContext,
    useMemo,
    useState
} from "react";
import {
    Container,
    Nav,
    Navbar as RBNavbar
} from "react-bootstrap";
import {
    AuthenticationContext,
    CartProductCountBadge,
    ShopContext
} from "@shopcrate/shopcrate-framework";

import HeaderBanner from "../HeaderBanner";
import NavbarExternalLink from "./NavbarExternalLink";
import NavbarLink from "./NavbarLink";

function Navbar() {
    const authentication = useContext(AuthenticationContext);
    const shop = useContext(ShopContext);

    const [showCollapsed, setShowCollapsed] = useState(false);
    const toggle = useMemo(() => {
        return () => {
            setShowCollapsed(!showCollapsed);
        }
    }, [showCollapsed]);
    const collapse = useMemo(() => {
        return () => {
            setShowCollapsed(false);
        }
    }, []);

    return (
        <React.Fragment>
            <HeaderBanner/>
            <RBNavbar bg="dark" variant="dark" expand="lg" expanded={ showCollapsed }>
                <Container>
                    <RBNavbar.Toggle aria-controls="basic-navbar-nav" onClick={ toggle }>
                        <i className="navbar-toggler-fa fas fa-bars fa-fw"/>
                        Menu
                    </RBNavbar.Toggle>
                    <RBNavbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto navbar-separators" style={{ textTransform: "uppercase" }}>
                            <NavbarExternalLink to="https://saralindenhols.nl" title="Welkom" onClick={ collapse }/>
                            <NavbarExternalLink to="https://saralindenhols.nl/blog" title="Blog" onClick={ collapse }/>
                            <NavbarLink to="/" title="Online cursussen" exact onClick={ collapse }/>
                            <NavbarExternalLink to="https://saralindenhols.nl/workshops-en-tutorials" title="Real life workshops" onClick={ collapse }/>
                            <NavbarExternalLink to="https://saralindenhols.nl/biblejournaling" title="Biblejournaling" onClick={ collapse }/>
                            <NavbarExternalLink to="https://saralindenhols.nl/tips-en-tools" title="Tips en Tools" onClick={ collapse }/>
                        </Nav>
                        <Nav className="navbar-separators">
                            { shop.shop && shop.shop.orderingEnabled && (
                                <NavbarLink
                                    to="/cart"
                                    onClick={ collapse }
                                    title={
                                        <span style={{ whiteSpace: "nowrap" }}>
                                            <i className="fas fa-shopping-cart"/>
                                            <CartProductCountBadge className="ml-2"/>
                                        </span>
                                    }
                                />
                            )}

                            { authentication.supported && (
                                <NavbarLink to="/account" title={ <i className="fas fa-user fa-fw"/> } onClick={ collapse }/>
                            )}
                        </Nav>
                    </RBNavbar.Collapse>
                </Container>
            </RBNavbar>
        </React.Fragment>
    );
}

export default React.memo(Navbar);
