import React, {
    createRef,
    useLayoutEffect,
    useState
} from "react";
import useWindowSize from "../hooks/WindowSize";

function Footer() {
    let footerRef = createRef();
    const [height, setHeight] = useState(0);
    const size = useWindowSize();

    useLayoutEffect(() => {
        if (footerRef.current) {
            setHeight(footerRef.current.offsetHeight);
        }
    }, [footerRef, size]);

    const firstYear = 2021;
    const currentYear = new Date().getFullYear();
    let yearString =  firstYear + " - " + currentYear;
    if(firstYear === currentYear) {
        yearString = String(currentYear);
    }

    return (
        <React.Fragment>
            <div style={{ height: (height + 25) + "px" }}/>
            <div ref={ footerRef } className="jumbotron jumbotron-fluid jumbotron-footer">
                <div className="container" translate="no">
                    <div className="row align-items-sm-center">
                        <div className="col-lg-6 col-sm-6 mb-4 mb-lg-0 text-center text-sm-left">
                            Copyright &copy; { yearString } Sara Lindenhols.
                        </div>
                        <div className="col-lg-6 col-sm-6 mb-4 mb-lg-0 text-center text-sm-right">
                            <small>
                                A website by  <a href="https://srproductions.nl" target="_blank" rel="noopener noreferrer">SR Productions</a>.
                                <br/>
                                Powered by <a href="https://shopcrate.nl" target="_blank" rel="noopener noreferrer">ShopCrate</a>.
                            </small>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default React.memo(Footer);
