import React, {
    useContext,
    useEffect
} from "react";
import {
    Switch,
    Route,
    Redirect
} from "react-router-dom";
import {
    ShopContext,
    useShopCrateRoutes
} from "@shopcrate/shopcrate-framework";

import Navbar from "./components/navbar/Navbar";
import ScrollToTop from "./hooks/ScrollToTop";
import Footer from "./components/Footer";
import useTracking from "./hooks/useTracking";

import Home from "./pages/Home";
import Page404 from "./pages/Page404";

import Layout from "./layout/Layout";
import ProductLayout from "./layout/ProductLayout";
import CategoryLayout from "./layout/CategoryLayout";

const layouts = {
    regular: Layout,
    checkout: Layout,
    account: Layout,
    product: ProductLayout,
    category: CategoryLayout
}

function ShopRouter() {
    useTracking();
    const shopContext = useContext(ShopContext);
    const shopCrateRoutes = useShopCrateRoutes(layouts);

    useEffect(() => {
        if(shopContext.courseVideoMaxWidth === 1000) {
            return;
        }
        shopContext.setCourseVideoMaxWidth(1000);
    }, [shopContext]);

    return (
        <React.Fragment>
            <Navbar/>
            <ScrollToTop/>

            <Switch>
                <Route path="/" exact component={Home}/>

                { shopCrateRoutes }

                <Route path={["/index", "/index.html", "/index.htm", "/index.php"]}>
                    <Redirect to="/"/>
                </Route>
                <Route path="/" component={Page404}/>
            </Switch>

            <Footer/>
        </React.Fragment>
    )
}

export default ShopRouter;
