import React from "react";
import { Helmet as ReactHelmet } from "react-helmet";

function Helmet({ title = null, sitename = "Sara Lindenhols", description = "Demo shop voor ShopCrate." }) {
    return (
        <ReactHelmet>
            <title>
                { title === null ? sitename : (title + " | " + sitename) }
            </title>
            <meta name="description" content={ description } />
        </ReactHelmet>
    );
}

export default React.memo(Helmet);
