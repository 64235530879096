import React, {
    useContext
} from "react";
import Skeleton from "react-loading-skeleton";
import {
    CategoryPageContext,
    ShopContext
} from "@shopcrate/shopcrate-framework";

import SaraProductCard from "./SaraProductCard";

function SaraCategoryPageProducts() {
    const categoryContext = useContext(CategoryPageContext);
    const shopContext = useContext(ShopContext);

    if(!categoryContext.category || !shopContext.shop) {
        return (
            <div className="mb-3">
                <div>
                    {[...Array(6)].map((value, index) => (
                        <div className="row mb-5" key={ index }>
                            <div className="col-12 col-md-4 mb-3 mb-md-0">
                                <Skeleton style={{ aspectRatio: "1/1" }}/>
                            </div>
                            <div className="col-12 col-md-8">
                                <h2 className="text-primary">
                                    <Skeleton/>
                                </h2>
                                <div style={{ fontSize: "1.1rem", fontWeight: 450, color: "#1e2c1e" }}>
                                    <Skeleton count={ 3 }/>
                                </div>
                                <Skeleton height={ 31 } width={ 120 }/>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }
    if(categoryContext.category.products.length === 0) {
        return (
            <div className="d-flex flex-column align-items-center">
                <h1>
                    <i className="fad fa-store"/>
                </h1>
                <h3>
                    Momenteel niets beschikbaar
                </h3>
                <p>
                    Op dit moment heb ik geen cursusen beschikbaar.
                </p>
            </div>
        )
    }

    return categoryContext.category.products.map((product, index) => (
        <div key={ index } className="mb-5">
            <SaraProductCard product={ product }/>
        </div>
    ));
}

export default React.memo(SaraCategoryPageProducts);
