import React, {
    useMemo
} from "react";
import {
    Link
} from "react-router-dom";
import ReactMarkdown from "react-markdown";

function ProductLink({ product, children, ...restProps }) {
    return (
        <Link to={ `/product/${product.id}/${product.url}` } { ...restProps }>
            { children }
        </Link>
    )
}

function SaraProductCard({ product }) {
    const image = useMemo(() => {
        if(product && product.images && product.images.length > 0) {
            return product.images[0];
        }
    }, [product]);
    return (
        <div className="row">
            <div className="col-12 col-md-4 mb-3 mb-md-0">
                <ProductLink product={ product }>
                    <div
                        className="d-flex justify-content-center align-items-center rounded"
                        style={{
                            backgroundColor: "white",
                            aspectRatio: "1/1",
                            overflow: "hidden"
                        }}
                    >
                        { image && (
                            <img
                                src={ image && image.url }
                                alt={ product.name }
                                style={{
                                    maxWidth: "100%",
                                    maxHeight: "100%"
                                }}
                            />
                        )}
                    </div>
                </ProductLink>
            </div>
            <div className="col-12 col-md-8">
                <h2 className="text-primary">
                    <ProductLink product={ product }>
                        { product.name }
                    </ProductLink>
                </h2>
                <div style={{ fontSize: "1.1rem", fontWeight: 450 }}>
                    <ReactMarkdown>
                        { product.summary }
                    </ReactMarkdown>
                </div>
                <ProductLink product={ product } className="btn btn-primary btn-sm">
                    Meer info
                </ProductLink>
            </div>
        </div>
    )
}

export default React.memo(SaraProductCard);
