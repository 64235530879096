import React from "react";
import {
    Link
} from "react-router-dom";

import Helmet from "../components/Helmet";

function Page404() {
    return (
        <React.Fragment>
            <Helmet title="404 Not Found"/>
            <div className="container mb-5">
                <div className="content">
                    <div className="content-title">
                        <h1>404 Not Found</h1>
                    </div>
                    <div className="content-body">
                        <p>De pagina die je probeerde te openen bestaat niet (meer).</p>
                        <Link to="/" className="btn btn-primary">
                            Bekijk alle producten
                        </Link>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Page404;
