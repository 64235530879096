import {
    useEffect
} from "react";
import {
    useHistory
} from "react-router-dom";
import ReactGA from "react-ga4";

const useTracking = () => {
    const { listen } = useHistory();

    useEffect(() => {
        return listen((location) => {
            // Wait until the new Helmet title has propagated so that we can send this to Google Analytics.
            setTimeout(() => {
                ReactGA.send({
                    hitType: "pageview",
                    page: location.pathname,
                    title: document.title
                });
            }, 300);
        });
    }, [listen]);
}

export default useTracking;
